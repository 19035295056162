
import { Vue, Component } from 'vue-property-decorator';
import { appName, appVersion } from '@/env';
import {
  readDashboardShowDrawer,
  readHasAdminAccess,
} from '@/store/main/getters';
import { commitSetDashboardShowDrawer } from '@/store/main/mutations';
import { dispatchUserLogOut } from '@/store/main/actions';
import { dispatchGetMyApps } from '@/store/apps/actions';
import { readMyApps } from '@/store/apps/getters';

const routeGuardMain = async (to, from, next) => {
  if (to.path === '/main') {
    next('/main/dashboard');
  } else {
    next();
  }
};

@Component
export default class Main extends Vue {
  private appName = appName;
  private appVersion = appVersion;

  private async mounted() {
    await dispatchGetMyApps(this.$store);
  }

  private get apps() {
    return readMyApps(this.$store);
  }

  private beforeRouteEnter(to, from, next) {
    routeGuardMain(to, from, next);
  }

  private beforeRouteUpdate(to, from, next) {
    routeGuardMain(to, from, next);
  }

  private get showDrawer() {
    return readDashboardShowDrawer(this.$store);
  }

  private set showDrawer(value) {
    commitSetDashboardShowDrawer(this.$store, value);
  }

  private switchShowDrawer() {
    commitSetDashboardShowDrawer(
      this.$store,
      !readDashboardShowDrawer(this.$store),
    );
  }

  private get hasAdminAccess() {
    return readHasAdminAccess(this.$store);
  }

  private async logout() {
    await dispatchUserLogOut(this.$store);
  }
}
